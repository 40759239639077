import { TweenMax } from 'gsap/src/uncompressed/TweenMax';

let options = {
    imgSrc: "assets/images/cornostella-notte.jpg",
    containerName: "ck-split-image-hover",
    rows: 3,
    columns: 3,
    margin: 1,
    animTime: 0.3
}

function ImageGrid(defaults) {
    let r = defaults.rows;
    let c = defaults.columns;
    let margin = defaults.margin;
    let placeholder = document.getElementsByClassName(defaults.containerName)[0];
    let container = document.createElement('div');

    if (!placeholder) {
        return;
    }

    container.className = "gridContainer";
    placeholder.appendChild(container);

    let gridTile;
    let w = (container.offsetWidth / c) -margin;
    let h = (container.offsetHeight / r) -margin;
    let arr = [];

    for (let i = 0, l = r * c; i < l; i++) {
        gridTile = document.createElement('div');
        gridTile.className = "gridTile";
        gridTile.style.backgroundImage = "url("+defaults.imgSrc+")";

        arr = [(w+margin)*(i%c), (h+margin)*Math.floor(i/c), ((w+margin)*(i%c)+w-margin), (h+margin)*Math.floor(i/c), ((w+margin)*(i%c)+w-margin), ((h+margin)*Math.floor(i/c) + h-margin), (w+margin)*(i%c), ((h+margin)*Math.floor(i/c) + h-margin)];

        // console.log(i + " ====>>> " + arr + " ||||| " + i%c  + " |||||| " + i/c);

        TweenMax.set(gridTile, {webkitClipPath:'polygon('+arr[0]+'px '+ arr[1]+'px,'+arr[2]+'px '+arr[3]+'px, '+arr[4]+'px '+ arr[5] +'px, '+arr[6]+'px '+ arr[7] +'px)', clipPath:'polygon('+arr[0]+'px '+ arr[1]+'px,'+arr[2]+'px '+arr[3]+'px, '+arr[4]+'px '+ arr[5] +'px, '+arr[6]+'px '+ arr[7] +'px)'});

        container.appendChild(gridTile);

        fixTilePosition(gridTile, i);
    }

    placeholder.addEventListener("mouseover", function(e){
        let allTiles = e.currentTarget.querySelectorAll(".gridTile");

        for (let t=0, le = allTiles.length; t < le; t++) {
            TweenMax.to(allTiles[t], defaults.animTime, {css:{backgroundPosition:"0px 0px"}, ease:Power1.easeOut});
        }
    })

    placeholder.addEventListener("mouseleave", function(e){
        let allTiles = e.currentTarget.querySelectorAll(".gridTile");

        for (let ti=0, len = allTiles.length; ti < len; ti++) {
            fixTilePosition(allTiles[ti], ti, defaults.animTime);
        }
    })

    function fixTilePosition(tile, ind, time)
    {
        if (time==null) {
            time=0;
        }
        let centr, centrCol, centrRow, offsetW, offsetH, left, top;

        centr = Math.floor(c * r / 2);
        centrCol = Math.ceil(centr/c);
        centrRow = Math.ceil(centr/r);

        offsetW = w/centrCol;
        offsetH = h/centrRow;

        left = (Math.round((ind % c - centrCol + 1) * offsetW));
        top = (Math.round((Math.floor(ind/c) - centrRow + 1) * offsetH));

        //console.log(left, top)

        TweenMax.to(tile, time, {css:{backgroundPosition:left+"px "+top+"px"}, ease:Power1.easeOut});
    }
}

// init plugin
ImageGrid(options);
