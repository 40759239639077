import { TweenMax } from 'gsap/src/uncompressed/TweenMax';

// options
let options = {
    imgSrc: "assets/images/main-image.jpg",
    containerName: "ck-square-fracture-hover",
    grid: false,
    tileWidth: 80,
    tileHeight: 80,
    mouseTrail: true
}

let tileWidth, tileHeight, numTiles, tileHolder, tileContainer;
let directionX, directionY;
let imgOriginalWidth, imgOriginalHeight;
let imgCoverWidth, imgCoverHeight;
let imageLoaded = false;

// init function
function ImageSquareFracture(defaults) {
    numTiles=0;
    tileWidth = defaults.tileWidth;
    tileHeight = defaults.tileHeight;

    tileContainer = document.getElementsByClassName(defaults.containerName)[0];

    if (!tileContainer) {
        return;
    }

    if (options.grid == false) {
        tileContainer.className += " noGrid";
    }

    //preload image and get original image size, then create tiles
    let image = new Image();

    image.src = options.imgSrc;
    image.onload = function(e) {
        imageLoaded = true;
        imgOriginalWidth = e.currentTarget.width;
        imgOriginalHeight = e.currentTarget.height;

        createTileHolder();
        checkTileNumber();
        positionImage();
        addListeners();
    };
}

function resizeHandler()
{
    if (imageLoaded == false) {
        return;
    }

    //not working yet

    checkTileNumber();
    positionImage();
}

function createTileHolder() {
    tileHolder = document.createElement('div');
    tileHolder.className = "tileHolder";
    tileHolder.style.position = "absolute";
    tileHolder.style.top = "50%";
    tileHolder.style.left = "50%";
    tileHolder.style.transform = "translate(-50%, -50%)";
    tileContainer.appendChild(tileHolder);
}

function checkTileNumber()
{
    tileHolder.style.width = Math.ceil(tileContainer.offsetWidth / tileWidth) * tileWidth + "px";
    tileHolder.style.height = Math.ceil(tileContainer.offsetHeight / tileHeight) * tileHeight + "px";

    let tilesFitInWindow = Math.ceil(tileContainer.offsetWidth / tileWidth) * Math.ceil(tileContainer.offsetHeight / tileHeight);
    if (numTiles < tilesFitInWindow) {
        for (let i=0, l=tilesFitInWindow-numTiles; i < l; i++) {
            addTiles();
        }
    } else if (numTiles > tilesFitInWindow) {
        for (let i=0, l=numTiles-tilesFitInWindow; i < l; i++) {
            removeTiles();
        }
    }
}


function addTiles()
{
    let tile = document.createElement('div');
    tile.className = "tile";

    //maintain aspect ratio
    imgCoverWidth = tileContainer.offsetWidth;
    imgCoverHeight = tileContainer.offsetHeight;

    if (imgOriginalWidth > imgOriginalHeight) {
        imgCoverHeight = imgOriginalHeight / imgOriginalWidth * imgCoverWidth;
    }
    else {
        imgCoverWidth = imgOriginalWidth / imgOriginalHeight * imgCoverHeight;
    }


    tile.style.background = 'url("'+options.imgSrc+'") no-repeat';
    tile.style.backgroundSize  = imgCoverWidth + "px " +  imgCoverHeight + "px";
    tile.style.width = tileWidth + "px";
    tile.style.height = tileHeight + "px";
    document.querySelectorAll(".tileHolder")[0].appendChild(tile);

    tile.addEventListener("mouseover", moveImage);

    numTiles++;
}

function removeTiles()
{
    let tileToRemove = document.querySelectorAll(".tile")[0];
    tileToRemove.removeEventListener("mouseover", moveImage);

    TweenMax.killTweensOf(tileToRemove);
    tileToRemove.parentNode.removeChild(tileToRemove);

    numTiles--;
}

function addListeners() {
    if (options.mouseTrail) {
        document.addEventListener('mousemove', function (event) {
            directionX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
            directionY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;
        });
    }
}

function positionImage() {
    for (let t=0, l=numTiles; t < l; t++)
    {
        let nowTile = document.querySelectorAll(".tile")[t];

        let left = (-nowTile.offsetLeft - (tileHolder.offsetLeft - (tileHolder.offsetWidth/2)));
        let top = (-nowTile.offsetTop - (tileHolder.offsetTop - (tileHolder.offsetHeight/2)));

        nowTile.style.backgroundPosition = left + "px " + top + "px";
    }
}

function resetImage(nowTile) {
    let left = (-nowTile.offsetLeft - (tileHolder.offsetLeft - (tileHolder.offsetWidth/2)));
    let top = (-nowTile.offsetTop - (tileHolder.offsetTop - (tileHolder.offsetHeight/2)));


    TweenMax.to(nowTile, 1, {backgroundPosition:left + "px " + top + "px", ease:Power1.easeInOut});
}


function moveImage(e) {
    let nowTile = e.currentTarget
    let minWidth = -tileContainer.offsetWidth+nowTile.offsetWidth;
    let minHeight = -tileContainer.offsetHeight+nowTile.offsetHeight;
    let nowLeftPos = (-nowTile.offsetLeft - (tileHolder.offsetLeft - (tileHolder.offsetWidth/2)));
    let nowTopPos = (-nowTile.offsetTop - (tileHolder.offsetTop - (tileHolder.offsetHeight/2)))
    let offset = 60;
    let left = nowLeftPos;
    let top = nowTopPos;

    if (options.mouseTrail) {
        //direction-aware movement
        if (directionX > 0) {
            left = nowLeftPos + offset;
        } else if (directionX < 0) {
            left = nowLeftPos - offset;
        }

        if (directionY > 0) {
            top = nowTopPos + offset;
        } else if (directionY < 0) {
            top = nowTopPos - offset;
        }
    }
    else {
        //random movement
        left = getRandomInt(nowLeftPos - offset , nowLeftPos + offset);
        top = getRandomInt(nowTopPos - offset, nowTopPos + offset);
    }

    // bounds
    if (left < minWidth)left=minWidth;
    if (left > 0)left=0;
    if (top < minHeight)top=minHeight;
    if (top > 0)top=0;

    //tween
    TweenMax.to(nowTile, 1.5, {backgroundPosition:left + "px " + top + "px", ease:Power1.easeOut, onComplete:resetImage, onCompleteParams:[nowTile]});
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

(function() {
    let throttle = function(type, name, obj) {
        obj = obj || window;
        let running = false;
        let func = function() {
            if (running) { return; }
            running = true;
            requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    /* init - you can init any event */
    throttle("resize", "optimizedResize");
})();


// init plugin
ImageSquareFracture(options);
