import { TweenMax } from 'gsap/src/uncompressed/TweenMax';
import './split-text';


function ckAppearingTextInit(options) {
	let text = document.querySelector('.ck-appearing-text-split');
	let split = new SplitText(text);

	function random(min, max){
		return (Math.random() * (max - min)) + min;
	}

	for (let char of split.chars) {
		let defaults = {
			opacity: 0,
			x: random(-500, 500),
			y: random(-500, 500),
			z: random(-500, 500),
			scale: .1,
			delay: char * .02,
			yoyo: true,
			repeat: -1,
			repeatDelay: 7
		}

		TweenMax.from(char, 2.5, defaults);
	}
}


// init
function initCustom() {
	ckAppearingTextInit();


	let imageText = document.querySelector('.ck-image-appearing-text');

	TweenMax.from(imageText, 2.5, {
		opacity: 0,
		yoyo: true,
		repeat: -1,
		repeatDelay: 7
	})
}

function ready(fn) {
	if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}

ready(initCustom);
